.e-grid .e-headercell {
  cursor: pointer;
  background: #f0f2f5 !important;
}

.e-grid .e-headertext {
  font-size: 14px !important;
}

.e-grid .e-table {
  cursor: pointer;
}

.e-grid .e-gridheader {
  padding-right: 0px !important;
}

.e-grid .e-content {
  overflow-y: hidden !important;
  min-height: 300px;
}

.e-grid .e-row:hover .e-rowcell {
  background-color: #f7f8fa !important;
}

.e-grid .e-toolbar-items {
  background-color: white !important;
}

.e-grid .e-gridpager .e-currentitem {
  background-color: #f0f2f5;
  background: #e0e0e0 !important;
  color: black;
}

.e-pager .e-currentitem.e-numericitem .e-focused {
  background: #e0e0e0 !important;
  color: black;
}
